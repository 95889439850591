import React from 'react'
import Layout from '../components/layout'
import '../styles/ProductDetail.css'
import { graphql } from 'gatsby'
import {Helmet} from 'react-helmet' 

import FloatingWhatsApp from 'react-floating-whatsapp'
import logoRamsko from '../images/logo4.jpg'
import favicon from '../images/favicon.ico'

const ProductDetail = ({data}) => {
  
  return (
    <div className='bg-white'>
      <Layout>

      <Helmet title="Products | Ramasko | راماسكو"
          meta={[
                    {
                        name:'charSet',
                        content:'utf-8',
                    },
                    {
                        name:'description',
                        content:'Ramasko',
                    }
                ]}
        />
        <Helmet>
          <link rel="icon" href={favicon} />
        </Helmet>
        
          <div className='xl:max-w-6xl 2xl:max-w-7xl lg:max-w-5xl md:max-w-3xl mx-auto px-4 sm:px-6'>
            <div className='flex justify-center sm:justify-end'>
            <h1 className='titleNameProductDetail text-center sm:text-right font-Almarai font-bold' dir='rtl'>{data.contentfulProducts.productName}</h1>
            </div>
            <div dir='rtl'>
              <div className=''>
                <div className='flex justify-center sm:block'>
                  <img className="imageProductDetail sm:float-right" src={data.contentfulProducts.productImage.file.url} alt="some images" />
                </div>
                <p className='detailTextProductDetail font-Almarai text-xl text-justify'>{data.contentfulProducts.productDetails.productDetails}</p>
              </div>
            </div>
          
            <div className='flex justify-center py-20'>
              <img className="tableImageProductDetail" src={data.contentfulProducts.productSpecifications.file.url} alt="some images" />
            </div>
          </div>
          <FloatingWhatsApp
          phoneNumber = "+966551161149"
          accountName = "راماسكو"
          statusMessage = "كتابة ..."
          chatMessage = "كيف يمكننا مساعدتك؟"
          avatar = {logoRamsko}
          allowClickAway
          allowEsc
          notificationSound = "true"
         />

      </Layout>
    </div>
  )
}

export default ProductDetail
export const query = graphql`
    query($slug: String!) {
      contentfulProducts( slug: {eq: $slug}) {
        productImage {
          file {
            url
          }
        }
        productName
        productSpecifications {
          file {
            url
          }
        }
        slug
        productDetails {
          productDetails
        }
      }
    }
  `
